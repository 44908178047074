<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    custom-class="drawer"
    :append-to-body="true"
    :with-header="withHeader"
    :size="size"
    :modal="false"
    :wrapperClosable="true"
    :destroy-on-close="true"
    :before-close="handleClose"
  >
    <div v-if="customTitle" slot="title">
      <slot name="title"></slot>
    </div>
    <slot v-else name="title"></slot>
    <div class="drawer-content">
      <slot></slot>
      <!-- <div class="close" @click="handleClose">
        关闭
        <i class="el-icon-error" />
      </div> -->
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 600
    },
    title: {
      type: String,
      default: 'title'
    },
    withHeader: {
      type: Boolean,
      default: true
    },
    customTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
       this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.drawer-content {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ffffff;
  .close {
    position: absolute;
    left: -20px;
    z-index: 1;
    top: 50%;
    margin-top: -64px;
    width: 32px;
    height: 108px;
    background: #ffffff;
    box-shadow: -4px 0px 6px 1px rgba(0, 0, 0, 0.08);
    writing-mode: vertical-lr;
    font-size: 14px;
    font-weight: 400;
    color: #B8C3DE;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    &::before{
        content:  '';
        width: 0;
        height: 0;
        top: -10px;
        position:absolute;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-right: 32px solid #ffffff;
    }
    &::after{
        content:  '';
        width: 0;
        height: 0;
        top: 99px;
        position:absolute;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-right: 32px solid #ffffff;
    }
  }
}
</style>
