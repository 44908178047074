var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.drawer,
        "custom-class": "drawer",
        "append-to-body": true,
        "with-header": _vm.withHeader,
        size: _vm.size,
        modal: false,
        wrapperClosable: true,
        "destroy-on-close": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _vm.customTitle
        ? _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [_vm._t("title")],
            2
          )
        : _vm._t("title"),
      _c("div", { staticClass: "drawer-content" }, [_vm._t("default")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }